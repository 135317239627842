import { useSignal } from "@preact/signals";
import { useEffect } from "preact/hooks";
import { loadMedia } from '../utils/media.ts';

export default function Banner({ path }: { path: string }) {
    const data = useSignal("/default.png")
    useEffect(() => {
        loadMedia(path).then(e => data.value = e) 
    }, [])

    return (<img src={data.value} class="border-0" alt=""
        style="object-fit: contain; height: 300px" />)
}